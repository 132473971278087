import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  LinkBox,
  LinkOverlay,
  Tag,
  TagLeftIcon,
  TagLabel,
  Text,
  Wrap,
} from "@chakra-ui/react"
import * as React from "react"
import { graphql, Link as GatsbyLink, PageProps } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"

import Layout from "../components/layout"
import { SEO } from "@avldev/gatsby-theme-core-ui/tools"
import { IClientStoryExcerpt } from "../components/clientStories"
import { FaBullhorn } from "react-icons/fa"

interface StoriesPageProps extends PageProps {
  data: {
    stories: {
      nodes: IClientStoryExcerpt[]
    }
  }
}

interface StoryUIChunk {
  story: IClientStoryExcerpt
  featured?: boolean
}

function StoryLeadIn({ featured, story }: StoryUIChunk): JSX.Element {
  return (
    <>
      <LinkOverlay
        as={GatsbyLink}
        fontSize={featured ? `4xl` : { base: `lg`, sm: `2xl` }}
        fontWeight="extrabold"
        letterSpacing="tight"
        lineHeight={featured ? 10 : 6}
        to={`/client-stories/${story.slug}/`}
        _hover={{
          borderBottom: `1px`,
          color: `red.500`,
        }}
      >
        {story.storyTitle}
      </LinkOverlay>
      <Text
        d={featured ? `block` : { base: `none`, sm: `block` }}
        fontSize={featured ? `lg` : `md`}
        mt={2}
      >
        {story.subtitle}
      </Text>
      <Text color="gray.400" mt={2}>
        {dayjs(story.createdAt).format(`MMMM Do, YYYY`)}
      </Text>
    </>
  )
}

function StoryImage({ story }: StoryUIChunk): JSX.Element {
  return (
    <Link as={GatsbyLink} to={`/client-stories/${story.slug}/`}>
      <Image
        alt={story.featuredImage.description}
        as={GatsbyImage}
        h="100%"
        image={story.featuredImage.gatsbyImageData}
        rounded="lg"
        shadow="lg"
        w="100%"
      />
    </Link>
  )
}

function ClientStory({ data, location }: StoriesPageProps): JSX.Element {
  dayjs.extend(advancedFormat)
  const { stories } = data

  const featuredStory = stories.nodes.find(
    (story: IClientStoryExcerpt) => story.showOnHomepage
  )
  const allButFeaturedStory = stories.nodes.filter(
    (story: IClientStoryExcerpt) => story.slug !== featuredStory.slug
  )

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Honest homeowner reviews and stories are the best gauge of our success. What made our clients choose Sundog Homes, and how was the experience?",
          image:
            featuredStory.featuredImage.gatsbyImageData.images.fallback.src,
          imageAlt:
            featuredStory.featuredImage.description || featuredStory.storyTitle,
        }}
        title="Client Stories, See What They Built | Sundog Homes"
        url={location.href}
      />
      <Flex
        align="center"
        flexDir="column"
        justify="center"
        maxW="95vw"
        mx="auto"
        w="800px"
      >
        <Heading my={8} size="2xl" fontWeight="extrabold">
          Client Stories
        </Heading>
        {featuredStory && (
          <LinkBox
            align="center"
            as={Flex}
            bg={{ base: `white`, md: `gray.50` }}
            flexDir={{ base: `column`, md: `row` }}
            justify="space-between"
            p={{ base: 2, sm: 4, md: 2 }}
            rounded="lg"
            shadow={{ base: `none`, md: `md` }}
            w="100%"
          >
            <Box
              order={{ base: 2, md: 1 }}
              p={{ base: 1, md: 4 }}
              w={{ base: `100%`, md: `35%` }}
            >
              <Tag
                colorScheme="cyan"
                mb={{ base: 2, md: 4 }}
                mt={{ base: 1, md: 0 }}
                variant="subtle"
              >
                <TagLeftIcon as={FaBullhorn} boxSize="12px" />
                <TagLabel fontWeight="bold">FEATURED STORY</TagLabel>
              </Tag>
              <br />
              <StoryLeadIn featured story={featuredStory} />
            </Box>
            <Box
              h="350px"
              order={{ base: 1, md: 2 }}
              p={{ base: 1, md: 4 }}
              w={{ base: `100%`, md: `65%` }}
            >
              <StoryImage story={featuredStory} />
            </Box>
          </LinkBox>
        )}
        <Divider mb={4} mt={8} />
        {stories.nodes.length > 1 && (
          <Wrap align="start" direction="row" spacing={0} w="100%">
            {allButFeaturedStory.map(
              (story: IClientStoryExcerpt, index: number): JSX.Element => (
                <LinkBox
                  as={Flex}
                  flexDir="column"
                  id={story.clientSurname}
                  key={index}
                  p={{ base: 2, md: 4 }}
                  textAlign="left"
                  w="50%"
                >
                  <Box h={{ base: `150px`, sm: `200px`, md: `250px` }} w="100%">
                    <StoryImage story={story} />
                  </Box>
                  <Box mt={2} w="100%">
                    <StoryLeadIn story={story} />
                  </Box>
                </LinkBox>
              )
            )}
          </Wrap>
        )}
      </Flex>
    </Layout>
  )
}

export default ClientStory

export const query = graphql`
  query {
    stories: allContentfulClientStory(
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...ClientStoryExcerpt
      }
    }
  }
`
